const REMEMBER_ME = 'REMEMBER_ME';
const NOT_REMEMBER_ME = 'NOT_REMEMBER_ME';
const USER_DATA = 'USER_DATA';

const initState = {
    isRememberMe: false,
    user: {},
    userData: {}
};

const authReduer = (state = initState, action) => {
    if (action.type === REMEMBER_ME) {
        return {
            ...state,
            isRememberMe: true,
            user: action.payload
        };
    }
    if (action.type === NOT_REMEMBER_ME) {
        return {
            ...state,
            isRememberMe: false,
            user: {
                email: '',
                password: ''
            }
        };
    }
    if (action.type === USER_DATA) {
        return {
            ...state,
            userData: action.payload
        };
    }

    return state;
};

export default authReduer;
