export const SET_STATE = 'SET_STATE';

const initState = {
    resetForm: {}
};

const resetFormReducer = (state = initState, action) => {
    if (action.type === SET_STATE) {
        return {
            ...state,
            resetForm: action.payload
        };
    }

    return state;
};

export default resetFormReducer;
