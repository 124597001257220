import { SHOW_TOAST, HIDE_TOAST } from '../actions/toastAction';

const initState = {
    toast: {
        show: false,
        message: '',
        type: ''
    }
};

const toastReducer = (state = initState, action) => {
    if (action.type === SHOW_TOAST) {
        return {
            ...state,
            toast: { show: true, ...action.payload }
        };
    }
    if (action.type === HIDE_TOAST) {
        return {
            ...state,
            toast: initState.toast
        };
    }

    return state;
};

export default toastReducer;
