export const RESET_STATE = 'RESET_STATE';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_CONTACT_COMPANY = 'SET_CONTACT_COMPANY';
export const SET_RATE_MATRIX = 'SET_RATE_MATRIX';

const initState = {
    resetForm: {},
    contactForm: {},
    financeForm: {},
    companyData: {
        componyType: '',
        componyName: '',
        limitedCompanyData: {}
    }
};

const contactFormReducer = (state = initState, action) => {
    console.log('state action console', action);
    if (action?.type === RESET_STATE) {
        return {
            ...state,
            resetForm: action.payload
        };
    }
    if (action?.type === SET_CONTACT) {
        return {
            ...state,
            contactForm: action.payload
        };
    }
    if (action?.type === SET_CONTACT_COMPANY) {
        return {
            ...state,
            companyData: action.payload
        };
    }
    if (action?.type === SET_RATE_MATRIX) {
        return {
            ...state,
            financeForm: action.payload
        };
    }

    return state;
};

export default contactFormReducer;
