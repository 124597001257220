/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable no-sequences */
// eslint-disable-next-line no-unused-vars
import React, { Suspense, useEffect, createContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect } from 'react-redux';
import routes from './routes';
import ToastComponent from './components/ToastComponent/ToastComponent';
import { hideToast } from './redux/actions/toastAction';
// import Loader from './Loader/Loader';

import './App.scss';

export const showContext = React.createContext();
// const state = useSelector((state) => state);

const App = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const onShowDetails = () => {
        setShowDetails(true);
    };
    const onHideDetails = () => {
        setShowDetails(false);
    };
    useEffect(() => {
        props.dispatch(
            loadLanguages({
                languages: {
                    en: require('./translations/english.json')
                }
            })
        );
    });

    window.onbeforeunload = (e) => {
        props.hideToast();
    };

    return (
        <div className='App'>
            <showContext.Provider value={{ showDetails, onHideDetails, onShowDetails }}>
                <Suspense fallback>
                    <ToastComponent />
                    {/* <Loader show={state?.mainLoaderReducer?.showLoader} /> */}
                    <BrowserRouter>
                        <Routes>
                            {routes.map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        exact={route.exact}
                                        path={route.path}
                                        element={route.component}
                                    />
                                );
                            })}
                        </Routes>
                    </BrowserRouter>
                </Suspense>
            </showContext.Provider>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = {
    hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));
