import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { multilanguage } from 'redux-multilanguage';

import Snackbar from '@mui/material/Snackbar';

import { hideToast } from '../../redux/actions/toastAction';

import './ToastComponent.scss';
import { Alert, AlertTitle } from '@mui/material';

const ToastComponent = (props) => {
    const { toast: { type, show, message, title } } = props;
    const [showToast, setShowToast] = useState(false);
    let toastClass;

    if (type === 'success') {
        toastClass = 'success-txt';
    } else if (type === 'error') {
        toastClass = 'Error';
    } else {
        toastClass = '';
    }

    useEffect(() => {
        setShowToast(show);
    }, [show]);

    return (
        <div className='tostMain'>
            <Snackbar
                className={toastClass}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                open={showToast}
                onClose={() => {
                    setShowToast(false);
                    setTimeout(() => {
                        props.hideToast();
                    }, 1000);
                }}
            >
                <Alert>
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>

            </Snackbar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        toast: state.toastReducer.toast
    };
};

const mapDispatchToProps = {
    hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ToastComponent));
