import React, { lazy } from 'react';

import Layout from './layout/UserLayout';

// const Register = lazy(() => import(
//     './pages/Auth/Register/Register'
// ));

const Login = lazy(() => import(
    './pages/Auth/Login/Login'
));

const ForgotPassword = lazy(() => import(
    './pages/Auth/ForgotPassword/ForgotPassword'
));

const ResetPassword = lazy(() => import(
    './pages/Auth/ResetPassword/ResetPassword'
));

const SetPassword = lazy(() => import(
    './pages/Auth/SetPassword/SetPassword'
));

const ChangePassword = lazy(() => import(
    './pages/ChangePassword/ChangePassword'
));

const Dashboard = lazy(() => import(
    './pages/DashBoard/DashBoard'
));

const FinanceCalculator = lazy(() => import(
    './pages/FinanceCalculator/FinanceCalculator'
));
const EnquiryHistory = lazy(() => import(
    './pages/EnquiryHistory/EnquiryHistory'
));
const ContactDetails = lazy(() => import(
    './pages/ContactDetails/ContactDetails'
));
const ContactPortman = lazy(() => import(
    './pages/ContactPortman/ContactPortman'
));

const Calculator = lazy(() => import(
    './pages/Calculator/Calculator'
));

const UserAccount = lazy(() => import(
    './pages/UserAccount/UserAccount'
));

const ThankYou = lazy(() => import(
    './pages/ThankYou/ThankYou'
));

const PageNotFound = lazy(() => import(
    './pages/PageNotFound/PageNotFound'
));

const routes = [
    {
        path: '',
        component: <Login />,
        authenticate: false,
        exact: true
    },
    // {
    //     path: 'register',
    //     component: <Register />,
    //     authenticate: false,
    //     exact: true
    // },
    {
        path: 'login',
        component: <Login />,
        authenticate: false,
        exact: true
    },
    {
        path: 'forgotpassword',
        component: <ForgotPassword />,
        authenticate: false,
        exact: true
    },
    {
        // path: 'resetpassword/:User_id/:accessToken',
        path: 'resetpassword',
        component: <ResetPassword />,
        authenticate: false,
        exact: true
    },
    {
        path: 'setpassword',
        component: <SetPassword />,
        authenticate: false,
        exact: true
    },
    {
        path: '/dashboard',
        component: <Dashboard />,
        exact: true
    },
    {
        path: '/*',
        component: <Layout />,
        authenticate: false,
        exact: true
    }
];

export const LayoutRoute = [
    {
        path: '/UserAccount',
        component: <UserAccount />,
        exact: true
    },
    {
        path: '/financecalculator',
        component: <FinanceCalculator />,
        exact: true
    },
    {
        path: '/enquiryhistory',
        component: <EnquiryHistory />,
        exact: true
    },
    {
        path: '/contactdetail',
        component: <ContactDetails />,
        exact: true
    },
    {
        path: '/contactportman',
        component: <ContactPortman />,
        exact: true
    },
    {
        path: '/calculator',
        component: <Calculator />,
        exact: true
    },
    {
        path: '/changepassword',
        component: <ChangePassword />,
        exact: true
    },
    {
        path: '/thankyou',
        component: <ThankYou />,
        exact: true
    },
    {
        path: '/*',
        component: <PageNotFound />,
        authenticate: false,
        exact: true
    }
];

export default routes;
