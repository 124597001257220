export const GET_LEAD_DATA = 'GET_LEAD_DATA';

export const initialState = {
    leads: []
};

export default function leadReducer (state = initialState, action) {
    switch (action.type) {
    case GET_LEAD_DATA:
        return { ...state, leads: action.payload };
    default:
        return state;
    }
}
