
import loaderReducer from './loaderReducer';
import toastReducer from './toastReducer';
import { combineReducers } from 'redux';
import { createMultilanguageReducer } from 'redux-multilanguage';
import authReduer from './authReduer';
import resetFormReducer from './resetFormReducer';
import contactFormReducer from './contactFormReducer';
// import businessTypeReducer from './businessTypeReducer';
import enquiryReducer from './enquiryReducer';
import leadReducer from './leadReducer';
import mainLoaderReducer from './mainLoaderReducer';

const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: 'en' }),
    loading: loaderReducer,
    auth: authReduer,
    toastReducer,
    resetFormReducer,
    contactFormReducer,
    enquiryReducer,
    leadReducer,
    mainLoaderReducer
});

export default rootReducer;
