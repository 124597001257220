
export const GET_DATA = 'GET_DATA';

export const initialState = {
    posts: []
};

export default function enquiryReducer (state = initialState, action) {
    switch (action.type) {
    case GET_DATA:
        return { ...state, posts: action.payload };
    default:
        return state;
    }
}
